import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'

const defaultProps = {
  title: ``,
  description: false,
  pathname: false,
  image: false,
  children: null,
}

const SEO = ({ title, description, pathname, image, children }) => {
  const site = useSiteMetadata()

  const {
    siteTitle,
    siteTitleAlt: defaultTitle,
    siteUrl,
    siteDescription: defaultDescription,
    siteLanguage,
    siteImage: defaultImage,
    author,
  } = site

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    image: `${siteUrl}${image || defaultImage}`,
  }

  // const favicon = (
  //   `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  //     <title>white woman with dark hair tehnologist emoji</title>
  //     <text y=".9em" font-size="90%">
  //       👩🏻‍💻
  //     </text>
  //   </svg>`.trim()
  // );

  return (
    <Helmet
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${siteTitle}`}
      link={[
        {
          rel: 'icon',
          href: `data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22>
            <title>woman technologist emoji with white skin and dark hair</title>
            <text y=%22.9em%22 font-size=%2290%22>
              👩🏻‍💻
            </text>
          </svg>`,
        },
        {
          rel: 'alternate',
          type: 'application/rss+xml',
          title: 'madalyn.dev RSS Feed',
          href: `${siteUrl}/rss.xml`,
        },
      ]}
    >
      <html lang={siteLanguage} />
      <meta name='description' content={seo.description} />
      <meta name='image' content={seo.image} />
      <meta property='og:title' content={seo.title} />
      <meta property='og:url' content={seo.url} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:image' content={seo.image} />
      <meta property='og:type' content='website' />
      <meta property='og:image:alt' content={seo.description} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:url' content={seo.url} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.image} />
      <meta name='twitter:image:alt' content={seo.description} />
      <meta name='twitter:creator' content={author} />
      {children}
    </Helmet>
  )
}

export default SEO

SEO.defaultProps = defaultProps
