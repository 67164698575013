/** @jsx jsx */
import { jsx, useColorMode, Styled } from 'theme-ui'
import Header from './header'
import SEO from '../../components/seo'
import Footer from '../../components/footer'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'

export default ({ children, ...props }) => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`

  return (
    <Styled.root>
      <SkipNavLink sx={{ variant: 'skipLink' }}>Skip to Content</SkipNavLink>
      <SEO />
      <Header isDark={isDark} setColorMode={setColorMode} />
      <SkipNavContent />
      <div>{children}</div>
      <Footer isDark={isDark} />
    </Styled.root>
  )
}
