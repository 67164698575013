/** @jsx jsx */
import { jsx, Link as TLink } from 'theme-ui'
import { Link } from 'gatsby'
import ColorModeToggle from '../../components/color-toggle'

const Header = ({ isDark, setColorMode }) => {
  const toggleColorMode = e => {
    e.preventDefault()
    setColorMode(isDark ? `light` : `dark`)
  }

  const nav = [
    { title: 'blog', slug: 'blog' },
    { title: 'resume', href: 'https://resume.madalyn.dev' },
  ]
  const InternalLink = ({ item }) => (
    <TLink
      to={`/${item.slug}`}
      as={Link}
      sx={{
        variant: 'links.menu',
        p: [0, 1, 2],
      }}
    >
      {item.title}
    </TLink>
  )

  const ExternalLink = ({ item }) => (
    <a
      href={item.href}
      sx={{
        variant: 'links.menu',
        p: [0, 1, 2],
      }}
    >
      {item.title}
    </a>
  )

  return (
    <header
      sx={{
        display: 'flex',
        alignItems: 'center',
        bg: 'muted',
        borderBottomColor: 'text',
        borderBottom: '1px solid',
        variant: 'styles.header',
        pt: 3,
      }}
    >
      <Link
        to='/'
        sx={{
          variant: 'links.menuIcon',
        }}
      >
        <span
          sx={{
            variant: 'emojiSpan',
          }}
          role='img'
          aria-label='home - woman technologist emoji'
        >
          👩🏻‍💻
        </span>
      </Link>
      <div sx={{ mx: 'auto' }} />
      {nav.map(item =>
        item.href ? (
          <ExternalLink item={item} key={item.title} />
        ) : (
          <InternalLink item={item} key={item.slug} />
        )
      )}
      <ColorModeToggle
        isDark={isDark}
        toggle={toggleColorMode}
        sx={{ mr: 2 }}
      />
    </header>
  )
}

export default Header
