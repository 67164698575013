/** @jsx jsx */
import { jsx } from 'theme-ui'
import { SocialIcon } from 'react-social-icons'
import { useStaticQuery, graphql } from 'gatsby'
export default function Social({ isDark }) {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          social {
            name
            url
          }
        }
      }
    }
  `)
  const social = data.site.siteMetadata.social
  return (
    <ul
      sx={{
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'initial',
        m: 0,
        li: {
          ml: '0.2em',
          a: {
            width: '2em !important',
            height: '2em !important',
            minWidth: '48px !important',
            minHeight: '48px !important',
          },
        },
      }}
    >
      {social.map(item => (
        <li key={item.name}>
          <SocialIcon
            url={item.url}
            bgColor={'transparent'}
            fgColor={isDark ? '#ffffff' : '#02012c'}
            network={item.name.toLowerCase()}
            label={item.name}
          />
        </li>
      ))}
    </ul>
  )
}
