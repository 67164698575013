/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'

// Adapted from: https://github.com/LekoArts/gatsby-themes/blob/master/themes/gatsby-theme-emilia/src/components/colormode-toggle.tsx
// Which is adapted from: https://codepen.io/aaroniker/pen/KGpXZo and https://github.com/narative/gatsby-theme-novela/blob/714b6209c5bd61b220370e8a7ad84c0b1407946a/%40narative/gatsby-theme-novela/src/components/Navigation/Navigation.Header.tsx

const ColorModeToggle = ({ isDark, toggle }) => (
  <Flex
    sx={{
      alignItems: `center`,
      justifyContent: [`center`, `center`, `center`, `flex-end`],
    }}
  >
    <button
      onClick={toggle}
      type='button'
      aria-label={isDark ? `Activate Light mode` : `Activate Dark mode`}
      title={isDark ? `Activate Light mode` : `Activate Dark mode`}
      sx={{
        opacity: 0.65,
        position: `relative`,
        borderRadius: `5px`,
        width: `40px`,
        height: `25px`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        transition: `opacity 0.5s ease`,
        border: `none`,
        outline: `none`,
        background: `none`,
        cursor: `pointer`,
        padding: 0,
        appearance: `none`,
        '&:hover, &:focus': { opacity: 1 },
      }}
    >
      <div
        sx={{
          position: `relative`,
          width: `24px`,
          height: `24px`,
          borderRadius: `50%`,
          border: t => (isDark ? `4px solid ${t.colors.text}` : `none`),
          backgroundColor: isDark ? `text` : `transparent`,
          transform: isDark ? `scale(0.55)` : `scale(1)`,
          overflow: isDark ? `visible` : `hidden`,
          boxShadow: t =>
            isDark ? `none` : `inset 8px -8px 0px 0px ${t.colors.text}`,
          '&:before': {
            content: `""`,
            position: `absolute`,
            right: `-9px`,
            top: `-9px`,
            height: `24px`,
            width: `24px`,
            border: t => (isDark ? `2px solid ${t.colors.text}` : `none`),
            borderRadius: `50%`,
            transform: isDark ? `translate(14px, -14px)` : `translate(0, 0)`,
            opacity: isDark ? 0 : 1,
          },
          '&:after': {
            content: `""`,
            width: `8px`,
            height: `8px`,
            borderRadius: `50%`,
            margin: `-4px 0 0 -4px`,
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            boxShadow: t =>
              `0 -23px 0 ${t.colors.text}, 0 23px 0 ${t.colors.text}, 23px 0 0 ${t.colors.text}, -23px 0 0 ${t.colors.text}, 15px 15px 0 ${t.colors.text}, -15px 15px 0 ${t.colors.text}, 15px -15px 0 ${t.colors.text}, -15px -15px 0 ${t.colors.text}`,
            transform: isDark ? `scale(1)` : `scale(0)`,
          },
        }}
      />
    </button>
  </Flex>
)

export default ColorModeToggle
