/** @jsx jsx */
import { jsx } from 'theme-ui'
import Social from './social-menu'

const Footer = ({ isDark }) => (
  <footer
    sx={{
      fontSize: [1, 2, 3],
      bg: 'muted',
      color: 'text',
      variant: 'styles.footer',
      pt: 2,
      borderTop: '2px solid',
      borderColor: 'green',
      px: 2,
      py: 2,
      a: {
        color: 'text',
        border: '1px solid',
        borderColor: 'transparent',
        textDecorationColor: theme => `${theme.colors.accent}`,
        ':hover, :focus': {
          borderColor: 'accent',
          textDecoration: 'none',
          '&.social-icon': {
            borderRadius: '50%',
          },
        },
      },
      div: {
        display: 'flex',
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        '&:not(:last-of-type)': {
          mb: 2,
        },
        '* >': {
          mx: 'auto',
          pt: 1,
          mt: 1,
        },
      },
    }}
  >
    <div sx={{ alignItems: ['flex-start', 'center'] }}>
      <span>© 2020 Madalyn Parker</span>
      <span>
        Design by{' '}
        <a href='https://www.behance.net/fabiolarosso'>Fabiola Rosso</a>
      </span>
    </div>
    <div sx={{ alignItems: ['flex-end', 'center'] }}>
      <span>
        Made with{' '}
        <span role='img' aria-label='love'>
          &#10084;&#65039;
        </span>{' '}
        using <a href='https://www.gatsbyjs.com'>Gatsby</a> and{' '}
        <a href='https://www.netlify.com'>Netlify</a>
      </span>
      <Social isDark={isDark} />
    </div>
  </footer>
)

export default Footer
